import React, { useEffect, useState, useRef } from "react";
const ThreeDGallery = (props: any) => {
  const isClicked = useRef<boolean>(false);
  const threeDBoxRef = useRef<HTMLDivElement>(null);
  const threeDHeaderRef = useRef<HTMLDivElement>(null);
  const threeDCoords = useRef<{
    startX: number;
    startY: number;
    lastX: number;
    lastY: number;
  }>({
    startX: 150,
    startY: 150,
    lastX: 150,
    lastY: 150,
  });
  useEffect(() => {
    if (
      !threeDHeaderRef.current ||
      !threeDBoxRef.current ||
      !props.containerRef.current
    )
      return;
    const container = props.containerRef.current;

    /* 3D window refs */
    const threeDBox = threeDBoxRef.current;
    const threeDContainer = props.containerRef.current;
    const threeDHeader = threeDHeaderRef.current;

    threeDBox.style.top = `${threeDCoords.current.startY}px`;
    threeDBox.style.left = `${threeDCoords.current.startX}px`;

    if (props.is3DActive) {
      threeDBox.style.zIndex = "999";
    } else {
      threeDBox.style.zIndex = "1";
    }

    //3D window mouse events
    const onThreeDMouseDown = (e: MouseEvent) => {
      isClicked.current = true;
      threeDCoords.current.startX = e.clientX;
      threeDCoords.current.startY = e.clientY;
      props.handleActive("3d");
    };
    const onThreeDMouseUp = (e: MouseEvent) => {
      isClicked.current = false;
      threeDCoords.current.lastX = threeDBox.offsetLeft;
      threeDCoords.current.lastY = threeDBox.offsetTop;
    };
    const onThreeDMouseMove = (e: MouseEvent) => {
      if (!isClicked.current) return;
      const nextX =
        e.clientX - threeDCoords.current.startX + threeDCoords.current.lastX;
      const nextY =
        e.clientY - threeDCoords.current.startY + threeDCoords.current.lastY;
      threeDBox.style.top = `${nextY}px`;
      threeDBox.style.left = `${nextX}px`;
    };

    //3d event listeners
    threeDHeader.addEventListener("mousedown", onThreeDMouseDown);
    threeDHeader.addEventListener("mouseup", onThreeDMouseUp);
    threeDContainer.addEventListener("mousemove", onThreeDMouseMove);
    threeDContainer.addEventListener("mouseleave", onThreeDMouseUp);

    const cleanup = () => {
      threeDHeader.removeEventListener("mousedown", onThreeDMouseDown);
      threeDHeader.removeEventListener("mouseup", onThreeDMouseUp);
    };

    return cleanup;
  }, []);

  //Update z-index of active windows
  useEffect(() => {
    const threeDBox = threeDBoxRef.current;
    if (threeDBox) {
      if (props.is3DActive) {
        threeDBox.style.zIndex = "999";
      } else {
        threeDBox.style.zIndex = "1";
      }
    }
  }, [props.is3DActive]);
  return (
    <>
      <div
        ref={threeDBoxRef}
        className={
          props.is3DMinimized
            ? "folder-window hidden"
            : props.is3DActive
            ? "folder-window active"
            : "folder-window"
        }
      >
        <div ref={threeDHeaderRef} className="folder-header">
          <div className="top-bar">
            <div className="folder-label">
              <img src="/windows-icons/folder-empty.png" alt="My test file" />
              <span>3D</span>
            </div>
            <div className="folder-controls">
              <div
                onClick={() => props.handleMinimize("3D")}
                className="folder-controls-button minimize"
              ></div>
              {/* <div onClick={() => props.handleFullscreen("3D")}className="folder-controls-button maximize"></div> */}
              <div
                onClick={() => props.handleCloseClick("3D")}
                className="folder-controls-button close"
              ></div>
            </div>
          </div>
          <div className="menu-top">
            <ul>
              <li>
                File
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                Edit
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                View
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                Help
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
            </ul>
          </div>
          <div className="menu-bottom">
            <ul>
              <li>
                <img src="img/xp-arrow-icon-previous.png" alt="" />{" "}
                <span>Back</span>
              </li>
              <li>
                <img src="img/xp-arrow-icon-next.png" alt="" />
              </li>
            </ul>
            <ul>
              <li>Search</li>
            </ul>
          </div>
          <div className="address-bar">
            <span>Address</span>
            <div className="address-input-wrapper">
              <div className="address-input-icon">
                <img src="img/folder-empty.png" alt="" />
              </div>
              <input
                type="text"
                value="C:\Desktop\3D"
                placeholder="C:\Desktop\Folder Name"
                className="address-input"
              />
            </div>
          </div>
        </div>
        <div id="img-wrapper" className="folder-content">
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/1659299984.png", "him")}>
            <img src="./art-gallery/1659299984.png" />
            <p>him</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
          <div>
            <img src="./icon.jpg" />
            <p>icon.jpg</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ThreeDGallery;
