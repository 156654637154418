import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const LandingPage = (props: any) => {
  let navigate = useNavigate(); 
  const [showQuestion, setShowQuesiton] = useState<boolean>(true);
  useEffect(() => {
    const interval = setInterval(() => setShowQuesiton(!showQuestion), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [showQuestion]);

  const enterKey = (e: any) => {
    if (e.code === "Enter") {
      navigate("/home");
    }
  };
  const handleEnterClick = (e: any) => {
      navigate("/home");
  };

  return (
    <>
    <div tabIndex={0} onKeyDown={enterKey} className="App">
      <div tabIndex={0} onKeyDown={enterKey} onClick={handleEnterClick} className="landing">
        <div className="tv">
          <img src="./tv.gif" />
        </div>
        <div className="enter" onClick={() => props.setUserHitEnter(true)}>
          * E n t e r . . . {showQuestion ? "?" : ""}
        </div>
      </div>
      </div>
    </>
  );
};
export default LandingPage;
