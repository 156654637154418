import React, { useEffect, useState } from "react";
import "./DiscordModal.scss";
const DiscordModal = (props: any) => {
  const handleModalClose = () => {};
  return (
    <div
      className={props.showDiscordWarning ? "modal-show" : "modal-hidden"}
      onClick={(event) => props.setShowDiscordWarning(false)}
    >
      <div className="modal-content">
        <span
          onClick={(event) => props.setShowDiscordWarning(false)}
          className="close"
        >
          &times;
        </span>
        <div className="modalText">This discord is ages 17+.</div>
        <div className="confirmButtons">
          <ul>
            <li className="modalButton">
              <a
                onClick={(event) => props.setShowDiscordWarning(false)}
                target={"_blank"}
              >
                <span>I am not 17+</span>
              </a>
            </li>
            <li className="modalButton">
              <a href="https://discord.gg/NRVpaDGaRf" target={"_blank"}>
                <span>I am 17+</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default DiscordModal;
