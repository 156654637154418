import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DiscordModal from "../DiscordModal/DiscordModal";
import LandingPage from "../LandingPage/LandingPage";
import TermsModal from "../TermsModal/TermsModal";
import "./main.scss";

const MainPage = () => {
  const [showDiscordWarning, setShowDiscordWarning] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);

  return (
    <div className="home withBackground">
      <DiscordModal
        setShowDiscordWarning={setShowDiscordWarning}
        showDiscordWarning={showDiscordWarning}
      />
      <TermsModal
        setShowTermsModal={setShowTermsModal}
        showTermsModal={showTermsModal}
      />
      <div className="iconWrapper">
        <img className="icon" src="./icon.jpg" />
        <div className="title">✨ Dei ✨</div>
      </div>
      <div className="buttonGrid">
        <ul>
          <li>
            <a href="https://ko-fi.com/marsh" target={"_blank"}>
              <img src="./button-icons/ko-fi-svgrepo-com.svg" />
              <span>ko-fi</span>
            </a>
          </li>
          <li>
            <a
              onClick={(event) => setShowDiscordWarning(true)}
              target={"_blank"}
            >
              <img src="./button-icons/discord-alt-svgrepo-com.svg" />
              <span>Discord</span>
            </a>
          </li>
          <li>
            <a href="https://www.tumblr.com/gremlinvision" target={"_blank"}>
              <img src="./button-icons/tumblr-svgrepo-com.svg" />
              <span>tumblr</span>
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCErWrAQR12sy0iFWpd3yzfg"
              target={"_blank"}
            >
              <img src="./button-icons/youtube-svgrepo-com.svg" />
              <span>youtube</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="buttonGrid">
        <ul>
          <li>
            <a
              className="termsButton"
              onClick={(event) => setShowTermsModal(true)}
              target={"_blank"}
            >
              <span className="termsList">Terms of Service</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MainPage;
