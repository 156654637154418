import React, { useState } from "react";
import "./App.scss";
import LandingPage from "./Components/LandingPage/LandingPage";
import MainPage from "./Components/MainPage/MainPage";
import AppShell from "./Components/AppShell";

const App = () => {
  return (
    <AppShell></AppShell>
  );
};

export default App;
