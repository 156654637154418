import React, { useEffect, useState, useRef } from "react";
const ImageViewer = (props: any) => {
  const isClicked = useRef<boolean>(false);
  const imageBoxRef = useRef<HTMLDivElement>(null);
  const imageHeaderRef = useRef<HTMLDivElement>(null);
  const imageCords = useRef<{
    startX: number;
    startY: number;
    lastX: number;
    lastY: number;
  }>({
    startX: 175,
    startY: 175,
    lastX: 175,
    lastY: 175,
  });
  useEffect(() => {
    if (
      !imageHeaderRef.current ||
      !imageBoxRef.current ||
      !props.containerRef.current
    )
      return;
    const container = props.containerRef.current;
    /* art window refs */
    const imageBox = imageBoxRef.current;
    const artContainer = props.containerRef.current;
    const imageHeader = imageHeaderRef.current;
    imageBox.style.top = `${imageCords.current.startY}px`;
    imageBox.style.left = `${imageCords.current.startX}px`;
    if (props.isImageActive) {
      imageBox.style.zIndex = "999";
    } else {
      imageBox.style.zIndex = "1";
    }
    //art window mouse events
    const onImageMouseDown = (e: MouseEvent) => {
      isClicked.current = true;
      imageCords.current.startX = e.clientX;
      imageCords.current.startY = e.clientY;
      props.handleActive("image");
    };
    const onImageMouseUp = (e: MouseEvent) => {
      isClicked.current = false;
      imageCords.current.lastX = imageBox.offsetLeft;
      imageCords.current.lastY = imageBox.offsetTop;
    };
    const onImageMouseMove = (e: MouseEvent) => {
      if (!isClicked.current) return;
      const nextX =
        e.clientX - imageCords.current.startX + imageCords.current.lastX;
      const nextY =
        e.clientY - imageCords.current.startY + imageCords.current.lastY;
      imageBox.style.top = `${nextY}px`;
      imageBox.style.left = `${nextX}px`;
      // props.setIsArtFullscreen(false);
    };
    //art event listeners
    imageHeader.addEventListener("mousedown", onImageMouseDown);
    imageHeader.addEventListener("mouseup", onImageMouseUp);
    container.addEventListener("mousemove", onImageMouseMove);
    container.addEventListener("mouseleave", onImageMouseUp);

    const cleanup = () => {
      imageHeader.removeEventListener("mousedown", onImageMouseDown);
      imageHeader.removeEventListener("mouseup", onImageMouseUp);
    };

    return cleanup;
  }, []);
  //Update z-index of active windows
  useEffect(() => {
    const imageBox = imageBoxRef.current;
    if (imageBox) {
      if (props.isImageActive) {
        imageBox.style.zIndex = "999";
      } else {
        imageBox.style.zIndex = "1";
      }
    }
  }, [props.isImageActive]);
  return (
    <>
      <div
        ref={imageBoxRef}
        className={
          props.isImageMinimized
            ? "folder-window hidden"
            : props.isImageActive
            ? "folder-window active"
            : "folder-window"
        }
      >
        <div ref={imageHeaderRef} className="folder-header">
          <div className="top-bar">
            <div className="folder-label">
              <img src="./img/pictures.png" alt="Images" />
              <span>{props.iamgeName}</span>
            </div>
            <div className="folder-controls">
              <div
                onClick={() => props.handleMinimize("Image")}
                className="folder-controls-button minimize"
              ></div>
              {/* <div
                  onClick={() => props.handleFullscreen("Art")}
                  className="folder-controls-button maximize"
                ></div> */}
              <div
                onClick={() => props.handleCloseClick("Image")}
                className="folder-controls-button close"
              ></div>
            </div>
          </div>
        </div>
          <div className="imageViewer">
            <img src={props.iamgePath} />
          </div>
      </div>
    </>
  );
};
export default ImageViewer;
