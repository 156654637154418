import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./WindowsXP.scss";

const WindowsXP = (props: any) => {
  const [showLoggingIn,setShowLoggingIn] = useState<boolean>()
  let navigate = useNavigate(); 

  const timeout = (delay: number) =>  {
    return new Promise( res => setTimeout(res, delay) );
}

const handleClick = async (e: any) => {
  setShowLoggingIn(true)
  await timeout(2000)
  navigate("/desktop");
};
  return (
    <>
      <div className="login-screen">
        <div className="login-screen__top"></div>
        <div className="login-screen__center">
          <div className="login-screen__instructions">
            <img
              src="./windows-xp-logo-white-text-transparent-bg-cropped.png"
              alt="Windows logo"
            />
            <span>To begin, click your user name</span>
          </div>
          <div className="login-screen__accounts">
            <div className="login-screen__account" onClick={handleClick}>
              <div className="login-screen__account-icon">
                <img
                  src="./icon.jpg"
                  alt="marsh"
                />
              </div>
              <div className="login-screen__account-details">
                <span className="login-screen__account-name">Marsh</span>
                { showLoggingIn ? <span className="loading-settings">Loading your personal settings</span> : null }
                <div></div>
                <div className="login-screen__password">
                  <span>Type your password</span>
                  <form>
                    <input type="password" />
                    <button className="login-screen__submit" type="submit">
                      <span></span>
                    </button>
                    <button className="login-screen__question" type="button">
                      <span></span>
                    </button>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="login-screen__bottom">
          <div className="login-screen__turn-off">
            <button className="login-screen__turn-off-icon"></button>{" "}
            <span>Turn off computer</span>
          </div>
          <div className="login-screen__login-info">
            <span>
              After you log on, you can add or change accounts.
              <br />
              Just go to Control Panel and click User Accounts.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default WindowsXP;
