import React, { useEffect, useState, useRef } from "react";
import ArtGallery from "./ArtGallery";
import "./Desktop.scss";
import Taskbar from "./Taskbar/Taskbar";
import ThreeDGallery from "./ThreeDGallery";
import cloneDeep from "lodash/cloneDeep";
import NotFound from "../NotFound/NotFound";
import ImageViewer from "./ImageViewer";
const Desktop = (props: any) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isArtMinimized, setIsArtMinimized] = useState<boolean>(true);
  const [is3DMinimized, setIs3DMinimized] = useState<boolean>(true);
  const [isImageMinimized, setIsImageMinimized] = useState<boolean>(true);

  const [isArtActive, setIsArtActive] = useState<boolean>(false);
  const [is3DActive, setIs3DActive] = useState<boolean>(false);
  const [isImageActive, setIsImageActive] = useState<boolean>(false);

  const [currentActive, setCurrentActive] = useState<string>("");
  const [selectedFoldersForTaskbar, setSelectedFoldersForTaskbar] = useState<
    string[]
  >([]);
  const [showStartMenu, setShowStartMenu] = useState<boolean>(false);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [iamgePath, setImagePath] = useState<string>("");
  const [iamgeName, setImageName] = useState<string>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      //1000 = 1 second
      setShowPopUp(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const handleFolderClick = (folderName: string, artPath?: string, artName?: string) => {
    let localArr: any = cloneDeep(selectedFoldersForTaskbar);
    if (folderName.toLocaleLowerCase() === "art") {
      setIsArtMinimized(false);
      setCurrentActive(folderName);
      setIsArtActive(true);
      setIs3DActive(false);
      setIsImageActive(false);
      if (!localArr.includes(folderName)) localArr.push(folderName);
    } else if (folderName.toLocaleLowerCase() === "3d") {
      setIs3DMinimized(false);
      setCurrentActive(folderName);
      setIsArtActive(false);
      setIs3DActive(true);
      setIsImageActive(false);
      if (!localArr.includes(folderName)) localArr.push(folderName);
    } else if (folderName.toLocaleLowerCase() === "image") {
      setIsImageMinimized(false);
      setCurrentActive(folderName);
      setIsArtActive(false);
      setIs3DActive(false);
      setIsImageActive(true);
      setImagePath(artPath ? artPath : "");
      setImageName(artName ? artName : "");
      if (!localArr.includes(folderName)) localArr.push(folderName);
    } else {
      localArr = [];
    }
    setSelectedFoldersForTaskbar(localArr);
  };
  const handleTaskbarClick = (folderName: string) => {
    if (folderName.toLocaleLowerCase() === "art") {
      setIsArtMinimized(!isArtMinimized);
      if (isArtActive && currentActive === folderName) {
        setIsArtActive(!isArtActive);
        setCurrentActive("");
      } else {
        setIsArtActive(true);
        setCurrentActive(folderName);
      }
    } else if (folderName.toLocaleLowerCase() === "3d") {
      setIs3DMinimized(!is3DMinimized);
      if (is3DActive && currentActive === folderName) {
        setIs3DActive(!is3DActive);
        setCurrentActive("");
      } else {
        setIs3DActive(true);
        setCurrentActive(folderName);
      }
    } else if (folderName.toLocaleLowerCase() === "image") {
      setIsImageMinimized(!isImageMinimized);
      if (isImageActive && currentActive === folderName) {
        setIsImageActive(!isImageActive);
        setCurrentActive("");
      } else {
        setIsImageActive(true);
        setCurrentActive(folderName);
      }
    } else {
      //TODO
    }
  };
  const handleCloseClick = (folderName: string) => {
    let localArr: any = cloneDeep(selectedFoldersForTaskbar);
    if (folderName.toLocaleLowerCase() === "art") {
      setIsArtMinimized(true);
      if (localArr.includes(folderName))
        localArr.splice(localArr.indexOf(folderName), 1);
    } else if (folderName.toLocaleLowerCase() === "3d") {
      setIs3DMinimized(true);
      if (localArr.includes(folderName))
        localArr.splice(localArr.indexOf(folderName), 1);
    } else if (folderName.toLocaleLowerCase() === "image") {
      setIsImageMinimized(true);
      if (localArr.includes(folderName))
        localArr.splice(localArr.indexOf(folderName), 1);
    } else {
    }
    console.log(localArr);
    setSelectedFoldersForTaskbar(localArr);
  };
  const handleActive = (folderName: string) => {
    if (folderName.toLocaleLowerCase() === "art") {
      setIsArtActive(true);
      setIs3DActive(false);
      setIsImageActive(false);
      setCurrentActive(folderName);
    } else if (folderName.toLocaleLowerCase() === "3d") {
      setIsArtActive(false);
      setIs3DActive(true);
      setIsImageActive(false);
      setCurrentActive(folderName);
    } else if (folderName.toLocaleLowerCase() === "image") {
      setIsArtActive(false);
      setIs3DActive(false);
      setIsImageActive(true);
      setCurrentActive(folderName);
    } else {
      setIsArtActive(false);
      setIs3DActive(false);
      setIsImageActive(false);
      setCurrentActive("");
    }
  };
  const handleMinimize = (folderName: string) => {
    if (folderName.toLocaleLowerCase() === "art") {
      setIsArtMinimized(true);
      if (
        currentActive.toLocaleLowerCase() === folderName.toLocaleLowerCase()
      ) {
        setCurrentActive("");
      }
    } else if (folderName.toLocaleLowerCase() === "3d") {
      setIs3DMinimized(true);
      if (
        currentActive.toLocaleLowerCase() === folderName.toLocaleLowerCase()
      ) {
        setCurrentActive("");
      }
    } else if (folderName.toLocaleLowerCase() === "image") {
      setIsImageMinimized(true);
      if (
        currentActive.toLocaleLowerCase() === folderName.toLocaleLowerCase()
      ) {
        setCurrentActive("");
      }
    } else {
      setCurrentActive("");
    }
  };
  return (
    <main
      onClick={() => {
        if (showStartMenu) setShowStartMenu(false);
      }}
    >
      {" "}
      {/* Close menu when opened by clicking anywhere*/}
      <div ref={containerRef} className="desktop-container">
        <div className="file-grid wallpaper-grid">
          {/* Individual icons */}
          <div className="cell" onClick={() => handleFolderClick("Art")}>
            <img src="/windows-icons/folder-empty.png" alt="folder" />
            <span className="cell-name">Art</span>
          </div>
          {/* <div className="cell" onClick={() => handleFolderClick("3D")}>
            <img src="/windows-icons/folder-empty.png" alt="folder" />
            <span className="cell-name">3D</span>
          </div> */}
          <div className="cell">
            <a href="https://ko-fi.com/marsh" target={"_blank"}>
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">ko-fi</span>
            </a>
          </div>
          <div className="cell">
            <a href="https://www.tumblr.com/gremlinvision" target={"_blank"}>
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">tumblr</span>
            </a>
          </div>
          <div className="cell">
            <a
              href="https://www.youtube.com/channel/UCErWrAQR12sy0iFWpd3yzfg"
              target={"_blank"}
            >
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">youtube</span>
            </a>
          </div>
          <div className="cell">
            <a
              href="https://discord.gg/NRVpaDGaRf"
              target={"_blank"}
            >
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">discord</span>
            </a>
          </div>
          <div className="cell">
            <a
              href="https://toyhou.se/Loner"
              target={"_blank"}
            >
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">toyhouse</span>
            </a>
          </div>
          <div className="cell">
            <a
              href="https://www.twitch.tv/gremlinvision"
              target={"_blank"}
            >
              <img src="/windows-icons/ie.png" alt="folder" />
              <span className="cell-name">twitch</span>
            </a>
          </div>
        </div>
        {/* Window to show images etc */}
        <ThreeDGallery
          containerRef={containerRef}
          is3DMinimized={is3DMinimized}
          handleMinimize={handleMinimize}
          handleCloseClick={handleCloseClick}
          is3DActive={is3DActive}
          handleActive={handleActive}
          handleFolderClick={handleFolderClick}
        />
        <ArtGallery
          containerRef={containerRef}
          isArtMinimized={isArtMinimized}
          handleMinimize={handleMinimize}
          handleCloseClick={handleCloseClick}
          isArtActive={isArtActive}
          handleActive={handleActive}
          handleFolderClick={handleFolderClick}
        />
        <ImageViewer
          containerRef={containerRef}
          isImageActive={isImageActive}
          isImageMinimized={isImageMinimized}
          handleMinimize={handleMinimize}
          handleCloseClick={handleCloseClick}
          handleActive={handleActive}
          iamgePath={iamgePath}
          iamgeName={iamgeName}

        />
      </div>
      {/* Windows task bar */}
      <div className="bar">
        <ul className="start">
          <li
            className="xopen"
            onClick={() => setShowStartMenu(!showStartMenu)}
          >
            <a href="#" className="program-image">
              <img src="img/windows.png" />
            </a>
            <a href="#">O WO</a>
          </li>
        </ul>

        <ul className="taskbar">
          {selectedFoldersForTaskbar.map((value) => (
            <li
              key={value}
              className={
                currentActive.toLocaleLowerCase() === value.toLocaleLowerCase()
                  ? "active"
                  : ""
              }
            >
              <div className="cell" onClick={() => handleTaskbarClick(value)}>
                <img src="/windows-icons/folder-empty.png" alt="folder" />
                <span className="cell-name">{value}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          height: showStartMenu ? 454 : 0,
          visibility: showStartMenu ? "visible" : "hidden",
          zIndex: "auto",
        }}
        className="slide-open
          "
      >
        <div className="top">
          <h1>Marsh</h1>
        </div>
        <div className="menu">
          <div className="programs">
            <ul>
              <li>
                <a href="#" className="program-image">
                  <img src="img/ie.png" alt="" />
                </a>
                <a href="#">Internet Explorer</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/paint.png" alt="" />
                </a>
                <a href="#">Paint</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/media-player.png" alt="" />
                </a>
                <a href="#">Windows Media Player</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/outlook-express.jpg" alt="" />
                </a>
                <a href="#">E-mail</a>
              </li>
            </ul>
          </div>
          <div className="system">
            <ul>
              <li>
                <a href="#" className="program-image">
                  <img src="img/documents.png" alt="" />
                </a>
                <a href="#">My Documents</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/pictures.png" alt="" />
                </a>
                <a href="#">My Pictures</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/music.png" alt="" />
                </a>
                <a href="#">My Music</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/computer.png" alt="" />
                </a>
                <a href="#">My Computer</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/control-panel.png" alt="" />
                </a>
                <a href="#">Control Panel</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/support-help.png" alt="" />
                </a>
                <a href="#">Help and Support</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/search.png" alt="" />
                </a>
                <a href="#">Search</a>
              </li>
              <li>
                <a href="#" className="program-image">
                  <img src="img/run.png" alt="" />
                </a>
                <a href="#">Run...</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom"></div>
      </div>
      {showPopUp ? (
        <div
          className="spam"
          style={{
            backgroundColor: "pink",
            position: "fixed",
            width: "50vw",
            height: "25vw",
            // border: "15px solid purple",
            display: "flex",
            zIndex: 999,
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              style={{ height: "100%" }}
              className="img"
              src="https://cdn.discordapp.com/attachments/429905389954400266/1112485592073248888/catboyjerma.png"
            />
          </div>
          <div style={{ marginTop: "15%" }}>
            <h1>CATBOYS in your computer???</h1>
            <h1>More likely then you think</h1>
            <h2>Click here to get rid of them </h2>
            <button
              onClick={() => setShowPopUp(false)}
              style={{
                background: "purple",
                color: "orange",
                height: "64px",
                width: "128px",
                borderRadius: "40px",
                cursor: "pointer",
              }}
            >
              Click me{" "}
            </button>
          </div>
        </div>
      ) : null}
    </main>
  );
};
export default Desktop;
