import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.scss";
const NotFound = () => {
  let navigate = useNavigate();
  const enterKey = (e: any) => {
    console.log(e);
    if (e.code === "Enter") {
      navigate("/home");
    }
  };
  const handleEnterClick = (e: any) => {
    navigate("/home");
  };
  return (
    <div tabIndex={0} onKeyDown={enterKey} className="container">
      <div
        tabIndex={0}
        onKeyDown={enterKey}
        onClick={handleEnterClick}
        className="imgWrapper"
      >
        <img className="img" src="./image01.png" />
        <div
          onKeyDown={enterKey}
          onClick={handleEnterClick}
          className="returnText"
        >
          Home
        </div>
      </div>
    </div>
  );
};
export default NotFound;
