import React, { useEffect, useState, useRef } from "react";
const ArtGallery = (props: any) => {
  const isClicked = useRef<boolean>(false);
  const artBoxRef = useRef<HTMLDivElement>(null);
  const artHeaderRef = useRef<HTMLDivElement>(null);
  const artCoords = useRef<{
    startX: number;
    startY: number;
    lastX: number;
    lastY: number;
  }>({
    startX: 150,
    startY: 150,
    lastX: 150,
    lastY: 150,
  });
  useEffect(() => {

    if (
      !artHeaderRef.current ||
      !artBoxRef.current ||
      !props.containerRef.current
    )
      return;
    const container = props.containerRef.current;
    /* art window refs */
    const artBox = artBoxRef.current;
    const artContainer = props.containerRef.current;
    const artHeader = artHeaderRef.current;
    artBox.style.top = `${artCoords.current.startY}px`;
    artBox.style.left = `${artCoords.current.startX}px`;
    if (props.isArtActive) {
      artBox.style.zIndex = "999";
    } else {
      artBox.style.zIndex = "1";
    }
    //art window mouse events
    const onArtMouseDown = (e: MouseEvent) => {
      isClicked.current = true;
      artCoords.current.startX = e.clientX;
      artCoords.current.startY = e.clientY;
      props.handleActive("art");
    };
    const onArtMouseUp = (e: MouseEvent) => {
      isClicked.current = false;
      artCoords.current.lastX = artBox.offsetLeft;
      artCoords.current.lastY = artBox.offsetTop;
    };
    const onArtMouseMove = (e: MouseEvent) => {
      if (!isClicked.current) return;
      const nextX = e.clientX - artCoords.current.startX + artCoords.current.lastX;
      const nextY = e.clientY - artCoords.current.startY + artCoords.current.lastY;
      artBox.style.top = `${nextY}px`;
      artBox.style.left = `${nextX}px`;
      // props.setIsArtFullscreen(false);
    };
    //art event listeners
    artHeader.addEventListener("mousedown", onArtMouseDown);
    artHeader.addEventListener("mouseup", onArtMouseUp);
    container.addEventListener("mousemove", onArtMouseMove);
    container.addEventListener("mouseleave", onArtMouseUp);

    const cleanup = () => {
      artHeader.removeEventListener("mousedown", onArtMouseDown);
      artHeader.removeEventListener("mouseup", onArtMouseUp);
    };

    return cleanup;
  }, []);
  //Update z-index of active windows
  useEffect(() => {
    const artBox = artBoxRef.current;
    if (artBox) {
      if (props.isArtActive) {
        artBox.style.zIndex = "999";
      } else {
        artBox.style.zIndex = "1";
      }
    }
  }, [props.isArtActive]);
  // useEffect(() => {
  //   const artBox = artBoxRef.current;
  //   // artCoords.current.startX = 0;
  //   // artCoords.current.startY = 0;
  //   // artCoords.current.lastX = 0;
  //   // artCoords.current.lastY = 0;
  //   if (artBox) {
  //     if(props.isArtFullscreen) {
  //       artBox.style.top = `0px`;
  //       artBox.style.left = `0px`;
  //     } else {
  //       return;
  //     }
  //   }
  // }, [props.isArtFullscreen]);
  return (
    <>
      <div
        ref={artBoxRef}
        className={
          props.isArtMinimized
            ? "folder-window hidden"
            : props.isArtActive
            ? "folder-window active"
            : "folder-window"
        }
      >
        <div ref={artHeaderRef} className="folder-header">
          <div className="top-bar">
            <div className="folder-label">
              <img src="/windows-icons/folder-empty.png" alt="My test file" />
              <span>Art</span>
            </div>
            <div className="folder-controls">
              <div
                onClick={() => props.handleMinimize("Art")}
                className="folder-controls-button minimize"
              ></div>
              {/* <div
                onClick={() => props.handleFullscreen("Art")}
                className="folder-controls-button maximize"
              ></div> */}
              <div
                onClick={() => props.handleCloseClick("Art")}
                className="folder-controls-button close"
              ></div>
            </div>
          </div>
          <div className="menu-top">
            <ul>
              <li>
                File
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                Edit
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                View
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
              <li>
                Help
                {/* <ul>
                  <li>option</li>
                  <li>option</li>
                  <li>option</li>
                </ul> */}
              </li>
            </ul>
          </div>
          <div className="menu-bottom">
            <ul>
              <li>
                <img src="img/xp-arrow-icon-previous.png" alt="" />{" "}
                <span>Back</span>
              </li>
              <li>
                <img src="img/xp-arrow-icon-next.png" alt="" />
              </li>
            </ul>
            <ul>
              <li>Search</li>
            </ul>
          </div>
          <div className="address-bar">
            <span>Address</span>
            <div className="address-input-wrapper">
              <div className="address-input-icon">
                <img src="img/folder-empty.png" alt="" />
              </div>
              <input
                type="text"
                value="C:\Desktop\Art"
                placeholder="C:\Desktop\Folder Name"
                className="address-input"
              />
            </div>
          </div>
        </div>
        <div id="img-wrapper" className="folder-content">
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/1659299984.png", "him.png")}>
            <img src="./art-gallery/1659299984.png" />
            <p>him.png</p>
          </div>
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/IMG_1782.png", "bear.png")}>
            <img src="./art-gallery/IMG_1782.png" />
            <p>bear.png</p>
          </div>
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/IMG_1792.png", "bear2.png")}>
            <img src="./art-gallery/IMG_1792.png" />
            <p>bear2.png</p>
          </div>
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/IMG_2025.png", "creature_3d.png")}>
            <img src="./art-gallery/IMG_2025.png" />
            <p>creature_3d.png</p>
          </div>
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/IMG_2027.png", "creature_2d.png")}>
            <img src="./art-gallery/IMG_2027.png" />
            <p>creature_2d.png</p>
          </div>
          <div onClick={() => props.handleFolderClick('Image', "./art-gallery/raffledone.png", "raffle_prize.png")}>
            <img src="./art-gallery/raffledone.png" />
            <p>raffle_prize.png</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArtGallery;
