import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Desktop from "./Desktop/Desktop";
import LandingPage from "./LandingPage/LandingPage";
import MainPage from "./MainPage/MainPage";
import NotFound from "./NotFound/NotFound";
import WindowsXP from "./WindowsXP/WindowsXP";

const AppShell = () => {

  useEffect(() => {
   
  }); 

  return (
    <div className="main withBackground">
      <Router>
        <Routes>
            <Route path="/mobile" element={<LandingPage/>}/>
            <Route path="/home" element={<MainPage />} /> 
            {/* <Route path="/art" element={<ArtTOS />} /> 
            <Route path="/designs" element={<DesignTOS />} /> */}
            <Route path="*" element={<NotFound/>}/>  
            <Route path="/" element={<WindowsXP/>}/>  
            <Route path="desktop" element={<Desktop/>}/>  
        </Routes>
      </Router>
    </div>
  );
};

export default AppShell;
