import React, { useEffect, useState } from "react";
import "./TermsModal.scss";
const TermsModal = (props: any) => {
  const handleModalClose = () => {};
  const [showArtTerms, setShowArtTerms] = useState<boolean>(true);
  const [showDesignTerms, setShowDesignTerms] = useState<boolean>(false);

  return (
    <div
      className={props.showTermsModal ? "term-modal-show" : "term-modal-hidden"}
    >
      <div className="term-modal-content">
        <span
          onClick={(event) => props.setShowTermsModal(false)}
          className="close"
        >
          &times;
        </span>
        <div className="confirmButtons">
          <ul>
            <li
              className={
                showArtTerms ? "term-modalButtonSelected" : "term-modalButton"
              }
              onClick={(event) => {
                setShowDesignTerms(false);
                setShowArtTerms(true);
              }}
            >
              <a>
                <span>Art</span>
              </a>
            </li>
            <li
              className={
                showDesignTerms
                  ? "term-modalButtonSelected"
                  : "term-modalButton"
              }
              onClick={(event) => {
                setShowDesignTerms(true);
                setShowArtTerms(false);
              }}
            >
              <a>
                <span>Designs</span>
              </a>
            </li>
          </ul>
        </div>
        {showArtTerms ? (
          <div className="term-modalText">
            <h2>Commissions</h2>
            <ul className="term-ul">
              <li>By commissioning me, you are purchasing my labor only</li>
              <li>
                I retain all copyrights over my work, which include but are not
                limited to the right to: distribute, reproduce, copy and/or use
                the image as a sample for sales or self-promotion.
              </li>
              <li>
                I have trouble seeing very light pastels and very dark colors-
                because of this, lighting, stylistic choices, and colors may not
                exactly match the reference sheet provided!
              </li>
              <li>
                I will interpret your character in my style, please be familiar
                with it if commissioning
              </li>
              <li>
                Average commission time is around one month turn-around unless
                stated otherwise
              </li>
              <li>
                Please do not ask for constant updates. Please wait at least two
                weeks
              </li>
              <li>
                I am free to reject your commission for any reason without
                explanation
              </li>
              <li>Do not ask me to lower my prices/haggle</li>
              <li>
                Save commissions as soon as they are received, I do not keep
                archives
              </li>
              <li>Commissions will be delivered in .PNG format.  No layered files</li>
              <li>
                All commissions are for personal use only unless stated
                otherwise
              </li>
              <li>I currently do not do commercial work</li>
            </ul>
            <h2>Will Not Draw</h2>
            <ul className="term-ul">
              <li>Non-consensual</li>
              <li>NSFW Feral</li>
              <li>Incest</li>
              <li>Diaper/Adult Baby Play</li>
              <li>NSFW Underage Characters</li>
              <li>Nazi/Racist Imagery</li>
              <li>Transphobic Imagery</li>
              <li>Hate or any hate related content</li>
            </ul>
            <h2>Commissioner Rights</h2>
            <ul className="term-ul">
              <li>
                You may re-post art to personal galleries with proper credit and
                links to my gallery
              </li>
              <li>Please avoid posting specifically to e621 or Reddit</li>
              <li>
                You are allowed to crop the art, with appropriate credit
              </li>
              <li>
                You may use your commissioned art on your personal site, for
                banners, etc., with appropriate credit
              </li>
            </ul>
            <h2>Commissioner Can Not</h2>
            <ul className="term-ul">
              <li>Claim work as your own</li>
              <li>
                Remove my signature/watermark. You may add your own along with my
                own
              </li>
              <li>
                Edit commissions. If you need edits please contact me and I may
                for an extra fee.
              </li>
              <li>Make copies of my art, prints, merchandise for reselling</li>
              <li>Resell the commission</li>
              <li>
                Use my art for NFTs and other crypto/block chain related services
              </li>
            </ul>
          </div>
        ) : null}
        {showDesignTerms ? (
          <div className="term-modalText">
            <h2>Designs</h2>
            <ul className="term-ul">
              <li>Designs are for personal use only</li>
              <li>If you plan to use for streams/videos please ask about</li>
              <li>Do not edit my original work, only the designs</li>
              <li>Do not make design into a closed species</li>
              <li>Credit me for my art if you decide to upload anywhere</li>
              <li>Sibling/Twin designs are not allowed</li>
            </ul>
            <h2>Re-Selling</h2>
            <ul className="term-ul">
              <li>If you have created art for the design, you may add to resale value</li>
              <li>Please price your added art at an appropriate value</li>
              <li>Reselling at unfair amounts with result in a blacklist</li>
              <li>Reselling free, traded, or gifted designs are not allowed</li>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default TermsModal;
